#about-container {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 520px;
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
  padding: 20px 32px;
  box-sizing: border-box;
  background: white;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.12);
  display: none;
  line-height: 1.6;
}

button {
  min-height: 32px;
  min-width: 32px;
  text-align: center;
  font-size: inherit;
  font-weight: normal;
  line-height: 1.4;
  box-sizing: border-box;
  padding: 2px;
  background-color: transparent;
  border-color: transparent;
  border-width: 1px;
  border-style: solid;
  border-radius: 3px;
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.18s ease-out 0s;
}

button:hover {
  opacity: 0.3;
  /* background-color: rgba(0, 0, 0, 0.08);
border-color: transparent; */
}

button img {
  width: 32px;
  height: 32px;
  margin-top: 0px;
}

#close-about {
  display: block;
  float: right;
}

#about-title {
  position: absolute;
  top: 20px;
  left: 32px;
  /* font-size: 15px; */
  display: inline-block;
  padding-top: 8px;
  /* color: rgba(0, 0, 0, 0.7); */
  /* font-weight: bold; */
  opacity: 1;
  transition: opacity 0.18s ease-out 0s;
}
#about-title:hover {
  cursor: pointer;
  color: #599deb;
}

#about-box {
  margin-top: 54px;
}

#about-container a:link {
  color: black;
  text-decoration: underline;
}
#about-container a:visited {
  color: #222222;
  text-decoration: underline;
}
#about-container a:hover {
  color: #888888;
  text-decoration: underline;
}
#about-container a:active {
  opacity: 0.7;
}
