.project-info p,
.project-info ul,
.project-info ol {
  max-width: 600px;
  line-height: 1.6;
}
.project-info ol {
  max-width: 560px;
}

.project-info img {
  display: block;
  max-width: 100%;
  margin-top: 2em;
  margin-bottom: 2em;
  max-height: 90vh;
}

.project-info a:link {
  color: white;
  text-decoration: underline;
}
.project-info a:visited {
  color: #dddddd;
  text-decoration: underline;
}
.project-info a:hover {
  color: #aaaaaa;
  text-decoration: underline;
}
.project-info a:active {
  opacity: 0.7;
}
