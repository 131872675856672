html {
  margin: 0;
  width: 100%;
  height: 100%;
  /* fixed position to prevent bouncing on iPhone 6 XL  */
  position: fixed;
  top: 0px;
  left: 0px;
  /* re-enable bouncing for the details screen on iphones  */
  -webkit-overflow-scrolling: touch;
  overscroll-behavior-y: contain;
}

body {
  margin: 0;
  font-family: "euclidcb", -apple-system, "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  background-color: white;
  font-size: 18px;
  /* Only containers should have a scrollbar.
  Like this, we can easily transform elements out of the body  */
  overflow: hidden;
  position: relative;
  top: 0px;
  left: 0px;
  color: rgba(0, 0, 0, 0.84);
  -webkit-overflow-scrolling: touch;
  overscroll-behavior-y: contain;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1 {
  font-size: 48px;
  font-weight: bold;
}
@media (max-width: 500px) {
  h1 {
    font-size: 36px;
  }
}
@media (max-width: 340px) {
  h1 {
    font-size: 28px;
  }
}

h3 {
  font-weight: bold;
  font-size: 28px;
}

a {
  cursor: pointer;
}

/* Scale canvas with resize attribute to full size */
canvas[resize] {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}

.full-wrapper {
  position: absolute;
  top: 0px;
  bottom: 0px;
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  /* avoid scrollbars */
  overflow: hidden;
}

#image-wrapper {
  /* Make it always the full height, even if the Chrome Mobile address bar 
  is displayed  */
  height: 100vh;
  max-height: 100vh;
}

#overview-wrapper {
  display: block;
}
.opened-details #overview-wrapper {
  display: none;
}

#details-wrapper {
  /* In Overview mode  */
  overflow-y: none;
}
.opened-details #details-wrapper {
  /* In Details mode  */
  overflow-y: auto;
}

#project-items-wrapper {
  overflow: visible;
}

#project-items-container {
  /* background-color: #1122ff22; */
  overflow: visible;
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
}

.project-bg-img {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0;
  transition: opacity 0.5s ease-in;
}

.project-bg-img.item-hover,
.project-bg-img.item-selected {
  transition-duration: 0.3s;
  opacity: 1;
  color: white;
}

.project-item {
  position: absolute;
  top: 0px;
  bottom: 0px;

  text-align: left;

  transition: color 0.3s ease-out;
  /* -webkit-text-fill-color 0.3s ease-out,
    -webkit-text-stroke-color 0.3s ease-out; */
  color: black;

  /* -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black; */
  /* text-shadow: -1px -1px 0 #000, 0 -1px 0 #000, 1px -1px 0 #000, 1px 0 0 #000,
    1px 1px 0 #000, 0 1px 0 #000, -1px 1px 0 #000, -1px 0 0 #000; */

  /* background-color: aliceblue; */
  /* border: solid 1px aqua; */
}

.project-item h3 {
  position: absolute;
  /* Pulls it a little bit toward the top   */
  /* + compensate for position relative to the padded box parent despite
       100% refering to the full height.  */
  top: calc(46% - 22px);
  transform: translateY(-100%);
  white-space: nowrap;

  /* text-transform: uppercase; */

  transition: transform 0.3s ease-in-out;

  /* -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black; */
  /* text-shadow: -1px -1px 0 #000, 0 -1px 0 #000, 1px -1px 0 #000, 1px 0 0 #000,
    1px 1px 0 #000, 0 1px 0 #000, -1px 1px 0 #000, -1px 0 0 #000; */
}

.project-item p {
  position: absolute;
  top: calc(42% - 22px);
  padding-top: 18px;
  /* transform: translateY(-50%); */

  font-weight: normal;
  font-style: normal;
  line-height: 1.4;
  opacity: 0;
  transition: opacity 0.3s ease-out, transform 0.3s ease-out;
}

.project-item p.category {
  opacity: 0.4;
}

.project-item a {
  display: inline-block;
  width: 100%;
  height: 100%;
  cursor: pointer;
  transition: color 0.3s ease-out;
  /* Necessary to have the text wrap correctly and respect the padding */
  position: relative;
}

.project-item-padder {
  padding: 22px 42px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}

.project-item a:link {
  color: black;
  text-decoration: none;
}
.project-item a:visited {
  color: black;
}
.project-item.item-hover a {
  color: white;
  text-decoration: underline;
}
.project-item a:active {
  opacity: 0.7;
}

/* .project-item a:focus,
.project-item a:not([disabled]):hover:focus {
  color: red;
  border-color: red;
  border-radius: 2px;
  box-shadow: "0px 2px 4px 2px #B8370033";
  outline: none;
} */

.project-item.item-hover {
  color: white;
}

.project-item.item-hover p {
  opacity: 1;
  color: white;
  /* transform: translateY(calc(-50% + 30px)); */
}

.project-item.item-hover p.category {
  opacity: 0;
}

.project-details-container {
  width: 100%;
  min-height: 100%;
  height: 100%;
  max-height: 100%;
  /* padding-top: 100%; */

  transform: translateY(100%);
  /* Necessary for scroll */
  box-sizing: border-box;
}

.project-details {
  transform: translateY(40px);
  /* Prefer to do transition with GSAP for more control */
  /* transition: transform 0.3s ease-in-out;
  transition-delay: 1200ms; */
  background-color: black;
  color: white;
  /* border-top: solid 1px rgba(0, 0, 0, 0.3); */
  padding: 18px 32px;
  padding-top: 7px;
  min-height: 100vh;
  /* box-shadow: 0px 18px 28px 28px rgba(0, 0, 0, 0.2); */
}

.project-details h1 {
  margin-top: 0px;
  margin-bottom: 0.3em;
}

h1 #project-name {
  /* Compensate for the (now removed) whitespace that disappears in production */
  padding-left: 0.15em;
  padding-right: 0.15em;
}

h1 #project-year {
  font-size: 18px;
  font-weight: normal;
}

h1 #back-button {
  padding-top: 6px;
  height: 1em;
  width: 1em;
  position: relative;
  top: 0.16em;
  left: -0.03em;
}

#project-details-title {
  /* For scroll-for-more positioning */
  position: relative;
}

.scroll-for-more-indicator {
  position: absolute;
  top: 52px;
  opacity: 0.6;
}
.scroll-for-more-indicator path {
  transform-origin: 50% 50%;
}
#scroll-indicator1 {
  left: 6px;
}
#scroll-indicator2 {
  right: 6px;
}

#project-description {
  display: none;
}

#details-iframe-container {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: calc(100% - 88px);
}
#details-iframe-container iframe {
  width: 100%;
  height: 100%;
  border-width: 0px;
  background: none;
}
#details-iframe-container.video iframe {
  background: black;
}
